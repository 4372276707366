import {React, useState, useEffect } from "react";
import './App.css';
import Navbar from "./Navbar";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import IosShareIcon from '@mui/icons-material/IosShare';
import OutdoorGrillOutlinedIcon from '@mui/icons-material/OutdoorGrillOutlined';
import KingBedIcon from '@mui/icons-material/KingBed';
import WeekendIcon from '@mui/icons-material/Weekend';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import Footer from "./Footer";
import DateFnsAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ShowerIcon from '@mui/icons-material/Shower';
import { Link, useParams } from "react-router-dom";
import Map from "./Map";
import axios from "axios";
import StarIcon from '@mui/icons-material/Star';
import GoogleMapReact from 'google-map-react';



const SingleProperty = () => {

        const [isLoading, setIsLoading] = useState(false);
    const [rooms , setRooms] = useState([]);
    const [rooml , setrooml] = useState();
    const [imgbig ,setimgbig] = useState();
    const [am , setam]  = useState([]);


        useEffect(() => {
                window.scrollTo(0, 0)

                     async function getData() {
      
                        setIsLoading(true);
        const res = await axios.get(`https://dev.techneeq.org/api/search-room?accommodation_id=${id}&invFrom=${checkIn}&invTo=${checkOut}&adults=${guest}&img=${1}`);
        const amu = await axios.get(`https://dev.techneeq.org/api/search-room?accommodation_id=${id}&invFrom=${checkIn}&invTo=${checkOut}&adults=${guest}`);
      
  
        // setNights(res.data.totalStay);
        let ar = [];
        // console.log(am.data.roomTypes);
        // console.log(amu.data.roomTypes[0].facilities)
        amu.data.roomTypes[0].facilities.map((data , i) => 
        
          ar.push(data.FacilityName)
        

        )

        setam(ar)
        // console.log(ar)
        console.log(am)
        setRooms(res.data.images);
        setimgbig(res.data.images[0].ImageUrl);

        setrooml(res.data.images.length);
        

   
        setIsLoading(false);
      
        // console.log(res.data.rooms);
        // setRooms(res.data.room);
        // setImages(res.data.roomsimages);
  
            
      }

        getData();
     
              }, [])

              const { id, checkIn, checkOut, accommodation,rate, address, guest, nights , acc_id } = useParams();
              // console.log(accommodation);


         
              let review1="";
              let reviewer1Name ="";
             
              let review2="";
              let reviewer2Name ="";

              let review3="";
              let reviewer3Name="";
              if(id == 38)
              {
                      reviewer1Name= "Momina Amjad";
                      review1 = "A Glamping Escapade - weekend getaway in an unusual accommodation in the heart of Murree!";
                      reviewer2Name= "Syed Rafay Ali";
                      review2="Located at Bansra Gali (only 4Kms from Sunny Bank, Main Murree) these classy Glamps – provide a perfect escape from the hustle bustle of the city, yet remaining close to all main locations in Murree";

                      review3="Another remote work location for this year! One of the reasons I really love working remotely.So far I've done this work-vacation at @orangeloft.pk , how cool is that?";
                      reviewer3Name ="Iqra Hameed";
              }
              else{

                      reviewer1Name= "Sabeen Rashid";
                      review1 = "Although we stayed only one night, we wished we could have stayed longer but I will visit again.";
                      reviewer2Name= "Waseem Ahmad";
                      review2="We really enjoyed the hotel and its facilities. The food was excellent as well. ";

                      review3="We arrived here and everything was good the room was spacious, clean, and comfortable, and I would highly recommend this hotel.";
                      reviewer3Name ="Alice";
                }

        // console.log(accommodation);
        // console.log(nights);

        const sidebarCheckIn = new Date(checkIn).toLocaleString('en-us',{month:'short', day:'numeric', year:'numeric'});
        const sidebarCheckOut = new Date(checkOut).toLocaleString('en-us',{month:'short', day:'numeric', year:'numeric'});
        

        const [show, setShow] = useState(false);
        const [text, setText] = useState("View All");
        const expand = () => {
                if(show == true)
                {
                        setText('View All');
                        setShow(false);

                }
                else{
                        
                        setText('Hide');
                        setShow(true);
                }
        }

    return (
        <div>
        <Navbar />
        <div className="container">

        <div className="row mt-5">
        <div className="col-sm-8">
        
        <h3>{accommodation}  </h3>
        <p>{address}</p>
        <div className="row">
        <div className="col-sm-1 no-gutters">
        <h4 className="rating text-white">4.4</h4>
        </div>
        <div className="col-sm-10 mt-2">
        <p><span className="font-weight-bold">Excellent </span><a href="#reviews"> See all 3 Reviews <KeyboardArrowRightIcon /></a></p>
        </div>

        </div>
        
        </div>
        <div className="col-sm-4 text-right ">
        <p><span className="font-weight-bold">{rate} PKR </span>/night<br />All taxes are excluded</p>

        <div className="row text-right row-margin1">
        
        <div className="col-sm-1 mr-1 mt-3" >
        {/* <IosShareIcon className="icon-styling" /> */}
        </div>
        <div className="col-sm-2 ml-1 mt-3">
        {/* <FavoriteBorderIcon className="icon-styling"/> */}
        </div>
        <div className="col-sm-8 ml-1 mt-3">
        <Link to={`/rooms/${id}/${checkIn}/${checkOut}/${accommodation}/${address}/${guest}/${nights}`}>
        <button  className="explore-btn">Book Now</button></Link>
        </div>
        </div>

        </div>

        </div>

        </div>

        <div className="container">
          {/* {
                rooms.map((room , i) => {
             
             console.log(room);
             
           return( */}

           
        <div className="row">
        
    

                                
                            
        

        <div className="col-sm-6">

        <img src={`https://marcopoloresortmurree.techneeq.org/storage/room-types/${imgbig}`}    className=" big-image w-100 " />

        </div>

       

     
        <div className="col-sm-6">

        <div className="row">

                {
                        rooms.map((room , i ) => {

                                
                                return (
                                        i > 0 ?   
                                <div className="col-sm-6 px-2" key={i} >
                                 
                                <img src= {`https://marcopoloresortmurree.techneeq.org/storage/room-types/${room.ImageUrl}`} className="room-image1 w-100 img-fluid" />
                            </div>
                            :null
                        ) 
                })
                }

        {/* <div className="col-sm-6 px-2">
        <img src= "" className="room-image1 w-100" />
        </div>
        <div className="col-sm-6 px-1">
        <img src="" className="room-image2 w-100" />
        </div>

        <div className="col-sm-6 px-2 py-0">
        <img src="" className="room-image1 w-100" />

        </div>

        <div className="col-sm-6 px-1">
        <img src="" className="room-image2 w-100" />
        </div> */}


        </div>

        {/* <div className="row">

       <div className="col-sm-6 px-2 py-0">
        <img src="" className="room-image1 w-100" />

        </div>

        <div className="col-sm-6 px-1">
        <img src="" className="room-image2 w-100" />
        </div>

        </div> */}


        </div>
        </div>
           {/* )

         })
         } */}
        </div>


        <div className="container">
        <div className="col-sm-9">

        <p className="font-weight-bold">Popular Amenities</p>

        <div className="row">
{
        am.map((d , i) => 
        
          
           i <= 5 ?   
           <div className="col-sm-4">

                
        <p><i class=""></i> {d}</p>
      
        </div>
        :null

)
        }

        {/* <div className="col-sm-8">
        <p><ShowerIcon /> Hot Water</p>
        </div> */}

        </div>

        {/* <div className="row">
        <div className="col-sm-4">
        <p><i class="fas fa-parking"></i> Parking available</p>
        </div>
        <div className="col-sm-8">
        <p><OutdoorGrillOutlinedIcon /> Kitchen</p>
        </div>
        </div> */}

        <div className="row">
        <div className="col-sm-12">
        <a onClick={expand} className="text-orange">{text} <KeyboardArrowDownIcon /></a>
        </div>
        </div>
        
        {show &&
                <div className="row">
{
        am.map((d , i) => 
        
          
           i > 5 ?   
        <div className="col-sm-4">
        <p><i class=""></i> {d}</p>
        </div>
              :null

              )
                      }
        {/* <div className="col-sm-8">
        <p><OutdoorGrillOutlinedIcon /> Kitchen</p>
        </div> */}
        </div>
        }

        <p className="font-weight-bold mt-4">Cleaning & Saferty Practices</p>
        <div className="row">
        <div className="col-sm-5">
        <p><i class="fas fa-hand-sparkles"></i> Cleaned with disinfectants</p>
        </div>
        <div className="col-sm-7">
        <p><i class="fas fa-video"></i> 24 hours security</p>
        </div>
        </div>

        <p className="font-weight-bold">Where you'll rest</p>
        <div className="row">
        <div className="col-sm-4">
        <div className="rest-border">
        <span className="ml-2"><KingBedIcon /></span><span className="ml-2"> <WeekendIcon /></span>
        <p className="font-weight-bold ml-2">Bedroom</p>
        <p className="ml-2">1 King bed, 1 sofa, 1 floor
        <br />
        mattress(on request)</p>
        </div>
        </div>
        </div>


        <p className="font-weight-bold mt-4">{nights} Night/s in {accommodation}</p>
        <p>{sidebarCheckIn} - {sidebarCheckOut}</p>

        
        

      <p className="font-weight-bold mt-4">Where you'll be</p>
      <div className="row">
      <div className="col-sm-12">
      <Map className="iframe-map" />
      <script async defer src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDHwdcWVGV2HKr5fHwkmehGMYCp-mXvqG8&callback=initMap"></script>
      </div>
      
      </div>
     
      
        </div>

        
</div>

<div className="container" id="reviews">
<div className="row mt-5">
<div className="col-sm-1 no-gutters">
<h4 className="rating text-white">4.4</h4>
</div>
<div className="col-sm-10 mt-2 mx-0 px-0">
<p><span className="font-weight-bold">Excellent </span></p>
</div>

</div>
</div>

<div className="container">
<div className="row no-gutters mt-4">
<div className="col-sm-1 px-0">
<img src="/images/room1.jpg" alt="" className="profilepic" />
</div>
<div className="col-sm-11 px-0">
<span className="font-weight-bold">{reviewer1Name}</span><br/>
<span className="font-weight-bold mr-1">5.0</span>
<StarIcon className="rating-icon" /> 
<StarIcon className="rating-icon" /> 
<StarIcon className="rating-icon" /> 
<StarIcon className="rating-icon" /> 
<StarIcon className="rating-icon" /> 

    
</div>


</div>

<div className="row no-gutters">
<div className="col-sm-9">

<p className="mt-1">{review1}
</p>
  
</div>

<div className="col-sm-3"></div>


</div>



</div>

<div className="container">
<div className="row no-gutters mt-4">
<div className="col-sm-1 px-0">
<img src="/images/room1.jpg" alt="" className="profilepic" />
</div>
<div className="col-sm-11 px-0">
<span className="font-weight-bold">{reviewer2Name}</span><br/>
<span className="font-weight-bold mr-1">4.0</span>
<StarIcon className="rating-icon" /> 
<StarIcon className="rating-icon" /> 
<StarIcon className="rating-icon" /> 
<StarIcon className="rating-icon" /> 
    
</div>


</div>

<div className="row no-gutters">
<div className="col-sm-9">

<p className="mt-1">{review2}
</p>
  
</div>

<div className="col-sm-3"></div>


</div>



</div>

<div className="container">
<div className="row no-gutters mt-4">
<div className="col-sm-1 px-0">
<img src="/images/room1.jpg" alt="" className="profilepic" />
</div>
<div className="col-sm-11 px-0">
<span className="font-weight-bold">{reviewer3Name}</span><br/>
<span className="font-weight-bold mr-1">4.0</span>
<StarIcon className="rating-icon" /> 
<StarIcon className="rating-icon" /> 
<StarIcon className="rating-icon" /> 
<StarIcon className="rating-icon" /> 
    
</div>


</div>

<div className="row no-gutters">
<div className="col-sm-9">

<p className="mt-1">{review3}
</p>
  
</div>

<div className="col-sm-3"></div>


</div>



</div>


        <Footer />

        </div>
        );
}

export default SingleProperty;