import React, { useState, useCallback, useEffect } from "react";
import Navbar from "./Navbar";
import './App.css';
import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp';
import ExpandLessSharpIcon from '@mui/icons-material/ExpandLessSharp';
import Footer from "./Footer";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import URLSearchParams from "use-url-search-params";
// import Slider from '@mui/material/Slider';
import { Modal, Button } from "react-bootstrap";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MapIcon from '@mui/icons-material/Map';
// test Slider import
import { Autoplay, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

const Rooms = () => {

    const {id, checkIn, checkOut, accommodation, address,guest,nights} = useParams();
    const checIn = "11-4-2022";
    const checOut = "15-4-2022";
   const sidebarCheckIn = new Date(checkIn).toLocaleString('en-us',{month:'short', day:'numeric'});
   const sidebarCheckOut = new Date(checkOut).toLocaleString('en-us',{month:'short', day:'numeric'});
  
  
    const [posts, setPosts] = useState([]);
    const [rooms , setRooms] = useState([]);
    const [images, setImages] = useState([]);
    const [roomr , setroomr] = useState();

    const [price, setPrice] = useState();
  
    const onHandleChange = (e) =>{
      console.log(e.target.value.split('/')[0])
      setPrice(e.target.value.split('/')[1]);
      setroomr(e.target.value.split('/')[0])
      setbtndisable(false);

    }

    const [test , settest] = useState([]);

      var test1 = [[] , [] , [] ];

      


    const [isLoading, setIsLoading] = useState(false);
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
          {
              breakpoint: 992,
              settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,

              }
          },
          {
              breakpoint: 767,
              settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,

              }
          },
          {
              breakpoint: 600,
              settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  arrows: false,
              }
          },

      ]
  };
    
  useEffect(() => {
      async function getData() {
        setIsLoading(true);
       
        const res = await axios.get(`https://dev.techneeq.org/api/search-room?accommodation_id=${id}&invFrom=${checkIn}&invTo=${checkOut}&adults=${guest}`);
  
        // setNights(res.data.totalStay);
        
      //  console.log(res.data)
            
       res.data.roomTypes.map((rate , i) => 

        

          test1[0].push(rate.roomInventories[0][4])


       )

 
        console.log(test1)
        setRooms(res.data.roomTypes);

   
        setIsLoading(false);
        // console.log(res.data.rooms);
        // setRooms(res.data.room);
        // setImages(res.data.roomsimages);
  
            
      }
      getData();
  },[]);
  
  
  
  const properties = Object.keys(rooms).length;
  // console.log(properties);
  
      const upArrow = <ExpandLessSharpIcon />;
      const downArrow = <ExpandMoreSharpIcon />;
      const [open, setOpen] = useState(upArrow);
      const [state, setState] = useState(downArrow);
      const [opacity, setOpacity] = useState(0);
  
      const appStyles={
        opacity:`${opacity}`
      }
  
  
      const toggle =() =>{
          if(open === upArrow){
              setOpen(downArrow);
          }
          else {
              setOpen(upArrow);
          }
        
      }
  
      const  [toggleHeart, setToggleHeart] = useState(false)
  
      
      const changeColor = useCallback(() =>{
       setToggleHeart(!toggleHeart)
      },[]);



      const [showModal, setShow] = useState(false);
      const [btndisable , setbtndisable] = useState(true);

      const [showModal1, setShow1] = useState(false);

      const handleClose1 = () => setShow1(false);

      const handleShow1 = () => {
          // setShow1(true);
        
      };
    

  const handleClose = () => setShow(false);

  const handleShow = () => {
      // setShow(true);
    
  };

  const [value, setValue] = useState([]);

  
      return (
          <div>
          <Navbar />
          
          <div className="container listing-container">
          
        
          <div className="row mt-5">
  
          <div className="col-sm-3 ">
          
          <div className="card p-3 mt-4">
          <span className="font-weight-bold text-orange">{accommodation}</span><br />

          <span className="text-orange">Location:</span><br />
          <div className="btn-image1">
          <button className="btn-orange-map" onClick={handleShow1}><MapIcon />Show Map</button>
          </div>

          <Modal show={showModal1} onHide={handleClose1} aria-labelledby="example-modal-sizes-title-lg" >
          <Modal.Body className="modal-body">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d421019.2107339187!2d73.14369356175466!3d34.47213102075724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38de1e42718e6715%3A0x681d2a128e7acee7!2sKunhar%20River!5e0!3m2!1sen!2s!4v1650624453581!5m2!1sen!2s" className="modal-map" />
           </Modal.Body>
        </Modal>



          <span><LocationOnIcon className="text-orange"/>{address}</span><br />
        
          <span className="text-orange">Check In:</span>
          <span>{sidebarCheckIn}, 2022</span><br />
          <span className="text-orange">Check Out:</span>
          <span>{sidebarCheckOut}, 2022</span>
          <br />
          <span className="text-orange">Policies:</span>
          <span className="policy-text">Check In Time: <b style={{float:"right"}}>14:00</b></span>
          <span className="policy-text">Check Out Time: <b style={{float:"right"}}>12:00</b></span>
          </div>
      
        
          </div>
  
          <div className="col-sm-9 mt-2">
          {isLoading ? 
            <div className="col-sm-12 text-center">
            <div className="spinner-border spinner-color text-center" role="status">
            <span className="sr-only">Loading...</span>
          </div>
            </div>
            :  
          rooms.map((room ,i) => {
         
            return (
              <div>
              {!room.RoomTypeName ? 
                i == 0 ? 
                <div className="card mt-3 p-5 text-center">
                <h4 className="text-danger">Please Select any other date range</h4>
                 <Link to="/" className="go-back" >Go Back</Link>
                </div>
                : <p></p>
              : 
             
              <div className="card mt-3" >
              <form>

             <div className="row no-gutters">
                 {/* {console.log(room,i)} */}
            <div className="col-sm-4 p-0 mt-3 mb-3 ml-3" style={{position:'relative'}} >
            
              <Swiper
                spaceBetween={30}
                centeredSlides={true}
                // autoplay={{
                //   delay: 3500,
                //   disableOnInteraction: false,
                // }}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper" style={{position:'absolute',width:'100%',height:'100%'}}
                >
                  {
                    room.room_type_images.map((item,index)=>
                    <SwiperSlide className="m-0 p-0" key={index} style={{position:'relative'}} >
                     
                       <img src={`https://marcopoloresortmurree.techneeq.org/storage/room-types/${item.ImageUrl}`}  alt=""  className="img-fluid " style={{height:'210px',position:'absolute', width:'100%' , borderRadius: '15px' }}   />
                    
                    </SwiperSlide>
                    )
                  }
              </Swiper>    
              {/* For testing purpose it is ended */}
              </div>
               
              
                <div className="col-sm-7 mt-4 ml-3 mr-0" >
              <div className="row">
              <div className="col-sm-12">
              <div className="row">

                
              <div className="col-sm-11">
     
             
             
              <span className='repo-text'> </span>
            
            
          
           <h5 key={room.RoomId} onClick={()=>{setValue([room.RoomTypeName,room. RoomTypeDescription,room.MaxPersons,room.NoOfBeds,room.BathRoomDetail,room.RackRate]); {handleShow();}}} className="nav-link2" ><b className="mb-2">{room.RoomTypeName}</b> 
              <br />{`${room.RoomTypeDescription } `}</h5>

              {/* <Modal show={showModal} onHide={handleClose} id={room.RoomTypeId} aria-labelledby="example-modal-sizes-title-lg">
              <Modal.Header >
              
                <span className="text-orange"><Modal.Title>{value[0]}</Modal.Title> </span>
                <span onClick={handleClose} className="nav-link1" style={{cursor: 'pointer'}}>X</span>
              </Modal.Header>
              <Modal.Body>
              <div className="px-3 py-2">
              <h5>Room Description:</h5>
        {value[1]}
        <br /><br />
        <b>{value[2]}</b> Max Persons &nbsp; 
        <b>{value[3]}</b> Beds &nbsp;
        <b>{value[4]}</b> Baths
        <br /><br />
        <h5>Room Rate:</h5>
        <span className="font-weight-bold text-orange">{value[5]}.PKR </span><span className="price">/night. All taxes and fees included</span>
        </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal> */}
            
              </div>



              
              <div className="col-sm-1">
              <i className={
                  toggleHeart ? 'heart active far fa-heart' : 'heart far fa-heart'
                } onClick={changeColor}></i>
              </div>
              </div>
              
              <br />

                  <p>{room.MaxPersons} persons {room.NoOfBeds} Bed {room.BathRoomDetail} Bathroom</p>
               
              </div>
              
          
              </div>
      
              <div className="row row-margin">
      
              <div className="col-sm-4 mr-1">
              <span>Number of Rooms</span>
              <select className="form-control" onChange={onHandleChange} required>

                <option value="0" >Select Room</option>
               {Array.from(Array(room.roomInventories[0][4] > 0 ? room.roomInventories[0][4] : null).keys())
               .map(key => (<option value= {   `${key+1}/${(key+1)*(room.rate_plans[0].totalRates)}` }>{key+1} PKR {(key+1) *(room.rate_plans[0].totalRates)  }</option> ))
                 
                  

                  
                 
                   
                
                        
                //  <option value={ room.rate_plans[0].totalRates }>{ room.rate_plans[0].totalRates  }</option>
                 
                  
               }
         
                     {/* <option value={1*room.rate_plans[0].DefaultRates}>1 (PKR {1*room.rate_plans[0].DefaultRates}) </option> */}

                    

               {/*   <option value={2*2000}>2 (PKR {2*2000}) </option>
                <option value={3*room.room_type.rate_plans[0].DefaultRates}>3 (PKR {3*room.room_type.rate_plans[0].DefaultRates}) </option>
              <option value={4*room.room_type.rate_plans[0].DefaultRates}>4 (PKR {4*room.room_type.rate_plans[0].DefaultRates}) </option>
              <option value={5*room.room_type.rate_plans[0].DefaultRates}>5 (PKR {4*room.room_type.rate_plans[0].DefaultRates}) </option>
              <option value={6*room.room_type.rate_plans[0].DefaultRates}>6 (PKR {4*room.room_type.rate_plans[0].DefaultRates}) </option>
              <option value={7*room.room_type.rate_plans[0].DefaultRates}>7 (PKR {4*room.room_type.rate_plans[0].DefaultRates}) </option> */}
            
              </select>
              </div>
      
              <div className="col-sm-4 mt-2">
              <p><span className="font-weight-bold ">{room.rate_plans[0].totalRates}.PKR </span><span className="price">/night<br />All taxes are excluded</span></p></div>
      
              <div className="col-sm-3 px-0 text-right mt-1">

              <Link to={`/book-now/${id}/${checkIn}/${checkOut}/${accommodation}/${address}/${guest}/${nights}/${price}/${roomr}/${room.roomInventories[0][2]}/${room.rate_plans[0].RatePlanId} `}>
              <button disabled={btndisable} className="booking-btn" >Book Now</button></Link>
             
              </div>
              </div>
      
              </div> 
             
              
              </div>
           

              </form>
              </div>
              
              }
                </div>


                );

                
              })
             }

            


            
         
  
       
  
  
          
  
  
  
        </div></div></div>
   
  
        <Footer />
         
  
        </div>
          
      );
  }
export default Rooms;