import {React, useState} from "react";
import Navbar from "./Navbar";
import './App.css';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from "react-router-dom";
import axios from "axios";

export default function Slider() {
    const todayDate = new Date().toISOString().slice(0, 10);

    const date = new Date(Date.now() + ( 3600 * 1000 * 24));
    const tomorrow = date.toISOString().slice(0, 10);
    const [guest, setGuest] = useState(1);
    const [checkIn, setCheckIn] = useState(todayDate);
    const [checkOut, setCheckOut] = useState(tomorrow);
    const [place, setPlace] = useState("Orangeloft Glamp park");
    const [btndisable , setbtndisable] = useState(true);

    const onHandleChange = (e) =>{
        setPlace(e.target.value);
        setbtndisable(false);
  
      }

    return (
        <div>
        <div className="container home-container mt-5">
        <div className="row">
        <div className="col-sm-12 text-center">
        <h1>Your Vaccation Starts Here</h1> 
        <p class="mob-p">Not sure where to spend lovely days? We're here to help</p>
        <a href="#stays" style={{ scrollBehavior: "smooth" }}>
        <button className="explore-btn mt-3">Explore</button></a>
        <div className="row mt-5" >
        <div className="col-sm-1">
        </div>


        <div className="col-sm-10 text-center">
        <div className="search-engine mt-3 w-100">
        <div className="row">
        <div className="col-sm-12">
        <form>
        <div className="row">

        <div className="col-sm-5">
        <div className="row">
        <div className="col-sm-12">
        <p className="search-label">Location</p>
        <select className="form-select location-dropdown "  onChange={onHandleChange}>
        <option>Where are you going?</option>
        <option>Orangeloft Glamp park</option>
        <option >Orangeloft Mushkpuri</option>
        <option>Orangeloft Wildside Camping</option>
        <option >Orangeloft Kalabagh</option>
        <option >Orangeloft Naraan</option>
        <option>Orangeloft Shogran</option>
        <option >Orangeloft Attabad Lake</option>
        <option >Orangeloft Skardu (Lower Kuchura Lake)</option>
        <option>Orangeloft Walled City Lahore</option>
        <option>Orangeloft Frontier Malam Jabba</option>
        <option >Orangeloft Mahodand Lake</option>

        </select>
        
        </div>
        </div>
        </div>

        <div className="col-sm-2">
        <div className="row">
        <div className="col-sm-12">
        <p className="search-label">Check In</p>
       
        <input type="date"
         placeholder="Add dates"
         className="date"
         id="check-in"
         name="checkIn"
         value={checkIn}
         required
         onChange={(e)=>setCheckIn(e.target.value)} />
        </div>
        </div>
        </div>
        <div className="col-sm-2">
        <div className="row">
        <div className="col-sm-12">
        <p className="search-label">Check Out</p>
       
        <input type="date" 
        placeholder="Add dates"
         className="date"
         id="check-out"
         name="checkOut"
         value={checkOut}
         required
         onChange={(e)=>setCheckOut(e.target.value)}  />
        </div>
        </div>
        </div>
        <div className="col-sm-2">
        <div className="row">
        <div className="col-sm-6">
        <p className="search-label">Guests</p>
        <input type="number"
         placeholder="Add Guests" 
         className="guest"
         value={guest}
         min="1"
         max="2"
         required
         onChange={(e)=>setGuest(e.target.value)} />
        </div>
        </div>
        </div>

        <div className="col-sm-1  search-col">
        <Link to={`listing/${place}/${checkIn}/${checkOut}/${guest}`} className="nav-link1">
        <button 
        className="no-border"
        disabled={btndisable}
       
        ><SearchIcon fontSize="large"  />
        </button>
        </Link>


        <Link to={`listing/${place}/${checkIn}/${checkOut}/${guest}`} className="mob-search">
        <button 
        className="Search-btn"
        >
        Search
        </button>
        </Link>
        
        </div>
        </div>
        </form>
        
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>


      
        </div>
        </div>
        </div>
    
    );

}

